import React, { useState, useEffect } from "react";
import WeatherDataApi from "../weatherApi/WeatherDataApi";

function Position() {
    const [coords, setcoords] = useState("");
    const [error, setError] = useState(false);
    const [loading, setloading] = useState(true);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setcoords({ latitude, longitude });
                setError(false);
            },
            () => {
                setError(true);
                setloading(false);
            }
        );
    }, []);

    return (
        <WeatherDataApi
            latitude={coords.latitude}
            longitude={coords.longitude}
            error={error}
            loading={loading}
            setloading={setloading}
        />
    );
}

export default Position;
