import React, { useState } from "react";
import Position from "./components/position/Position";
import Search from "./components/search/Search";
import SearchResults from "./components/searchresults/SearchResults";
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import SearchPage from "./components/searchpage/SearchPage";

function App() {
    const [search, setSearch] = useState("");
    const [clicked, setClicked] = useState("main");
    const [keyPress, setkeyPress] = useState("");
    const [loading, setloading] = useState(true);
    const [index, setIndex] = useState(0);

    return (
        <div>
            <Router>
                <Search
                    setSearch={setSearch}
                    search={search}
                    setClicked={setClicked}
                    clicked={clicked}
                    setloading={setloading}
                    setIndex={setIndex}
                />
                <Routes>
                    <Route path="/" element={clicked === "main" && <Position />} />
                    <Route
                        path="/search"
                        element={
                            <SearchPage
                                setSearch={setSearch}
                                setClicked={setClicked}
                                setloading={setloading}
                                setIndex={setIndex}
                            />
                        }
                    />
                </Routes>
                {/* default search */}
                {clicked === search && (
                    <SearchResults
                        search={search}
                        setSearch={setSearch}
                        setloading={setloading}
                        loading={loading}
                        index={index}
                    />
                )}
            </Router>
        </div>
    );
}

export default App;
