import React from "react";
import "./Loader.css";

function Loader() {
    return (
        <div className="loader-container">
            <div className="loader-1"></div>
            <div className="loader-2"></div>
            <div className="loader-3"></div>
        </div>
    );
}

export default Loader;
