import React, { useState } from "react";
import "./Search.css";
import { Link } from "react-router-dom";
function SearchPage({ setSearch, setClicked, setloading, setIndex }) {
    const [place, setPlace] = useState("");

    const searchedPlace = (search) => {
        setloading(true);
        search === place && setSearch(search);
        setClicked(place);
        setIndex((prev) => prev + 1);
    };
    return (
        <div className="home-container">
            <div className="main-container">
                <form
                    id={place}
                    className="form-wrapper"
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                    // onKeyPress={(e) => {
                    //     e.key === "Enter" && e.target.id === place && searchedPlace(e.target.id);
                    // }}
                >
                    <input
                        type="text"
                        value={place}
                        id="search"
                        onChange={(e) => setPlace(e.target.value)}
                        placeholder="Search Place..."
                        required
                    />
                    <Link to="/">
                        <input
                            type="submit"
                            value="Search"
                            id="submit"
                            className={place}
                            onClick={(e) => searchedPlace(e.target.className)}
                        />
                    </Link>
                </form>
            </div>
        </div>
    );
}

export default SearchPage;
