import React, { useState } from "react";
import "./search.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import SearchResults from "../searchresults/SearchResults";
import { Link, useNavigate } from "react-router-dom";
function Search({ setSearch, search, setClicked, clicked, setloading, setIndex }) {
    const navigate = useNavigate();
    const [temp, settemp] = useState("");
    const [myError, setMyError] = useState(false);
    const [keypress, setkeypress] = useState(false);
    const searchFun = (val) => {
        (val === null || temp === null) && setMyError(true);
        val === temp && setSearch(val);
        setClicked(val);
        navigate("");
        setloading(true);
        setIndex((prev) => prev + 1);
    };

    return (
        <div className="row">
            <div className="container">
                <div className="col-8 col-6 col-12">
                    <Navbar variant="dark" bg="dark" expand="lg">
                        <Container fluid>
                            <Navbar.Brand href="">Weather Forecaster</Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: "100px" }} navbarScroll>
                                    {/* <Link to="/" className="myStyle"> */}
                                    {/* <Nav.Link as={Link} to="/contact">anywords</Nav.Link> */}
                                    <Nav.Link as={Link} to="/" onClick={() => setClicked("main")}>
                                        My Location
                                    </Nav.Link>
                                    {/* </Link> */}
                                    {/* <Link to="/search" className="myStyle"> */}
                                    <Nav.Link as={Link} to="/search">
                                        Search
                                    </Nav.Link>
                                    {/* </Link> */}
                                </Nav>
                                <Form
                                    className="d-flex myForm"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <Form.Control
                                        id={temp}
                                        value={temp}
                                        type="search"
                                        placeholder="Search"
                                        className="me-4"
                                        aria-label="Search"
                                        onChange={(e) => settemp(e.target.value)}
                                        onKeyPress={(e) => {
                                            e.key === "Enter" && e.target.id === temp && searchFun(e.target.value);
                                        }}
                                    />
                                </Form>
                                <Form className="d-flex myForm">
                                    {/* <Button variant="outline-success" onClick={(e) => setClicked(true)}> */}
                                    <Link to="/">
                                        <Button
                                            type="submit"
                                            variant="outline-success"
                                            id={temp}
                                            onClick={(e) => searchFun(e.target.id)}
                                            className="me-4 mySpace"
                                        >
                                            Search
                                        </Button>
                                    </Link>
                                </Form>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </div>
        </div>
    );
}

export default Search;
