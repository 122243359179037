import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../App.css";
import Loader from "../loader/Loader";
import API_KEY from "../key/apiKey";

function SearchResults({ search, setSearch, setloading, loading, index }) {
    // const API_KEY = process.env.REACT_APP_API_KEY;
    const imgUrl = "https://openweathermap.org/img/w/";
    const [weather, setweather] = useState({});
    const [error, seteError] = useState(false);
    const weatherData = (place) => {
        const placeWeather = axios
            .get(`https://api.openweathermap.org/data/2.5/weather?q=${place}&units=metric&appid=${API_KEY}`)
            .then((result) => {
                seteError(false);
                setloading(false);
                const data = result.data;
                setweather({
                    city: data.name,
                    country: data.sys.country,
                    temperature: data.main.temp,
                    currentClimate: data.weather[0].main,
                    currentClimateDescription: data.weather[0].description,
                    icon: `${imgUrl}${data.weather[0].icon}.png`,
                    pressure: data.main.pressure,
                    humidity: data.main.humidity,
                    sea_level: data.main.sea_level,
                    ground_level: data.main.grnd_level,
                    wind_speed: data.wind.speed,
                });
            })
            .catch((err) => {
                seteError(true);
                setloading(false);
                console.clear();
                // console.error(err);
                // console.log(err.message)
                // setSearch("");
            });
    };

    useEffect(() => {
        weatherData(search);
    }, [index]);
    const weatherResult = loading ? (
        <Loader />
    ) : (
        <div className="home-container">
            <div className="main-container">
                <div className="card">
                    {error && search !== "" ? <h2> Unable to find weather data. Kindly check the place name </h2> : null}

                    {!error && search !== "" && weather.city && (
                        <h1>
                            City : {weather.city},{weather.country}
                            <img src={weather.icon} alt="weather-icon" className="icon" />
                        </h1>
                    )}
                    {search === "" ? <h2> Unable to find weather data. Kindly check the place name </h2> : null}

                    {!error && search !== "" && weather.temperature && <h2>Temperature : {weather.temperature} ℃</h2>}
                    {!error && search !== "" && weather.currentClimate && (
                        <h2> Current climate : {weather.currentClimate}</h2>
                    )}
                    {!error && search !== "" && weather.currentClimateDescription && (
                        <h2>Description : {weather.currentClimateDescription}</h2>
                    )}
                    {!error && search !== "" && weather.pressure && <h2>pressure : {weather.pressure} mbar </h2>}
                    {!error && search !== "" && weather.humidity && <h2>humidity : {weather.humidity}%</h2>}
                    {!error && search !== "" && weather.sea_level && <h2>Sea level : {weather.sea_level}</h2>}
                    {!error && search !== "" && weather.ground_level && <h2>Ground level : {weather.ground_level}</h2>}
                    {!error && search !== "" && weather.wind_speed && <h2>Wind speed : {weather.wind_speed} m/s</h2>}
                </div>
            </div>
        </div>
    );

    return weatherResult;
}

export default SearchResults;
