import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../App.css";
import Loader from "../loader/Loader";
import API_KEY from "../key/apiKey";

function WeatherDataApi({ latitude, longitude, error, loading, setloading }) {
    // const API_KEY = process.env.REACT_APP_API_KEY;
    const [weather, setWeather] = useState({});
    const WeatherimgUrl = "https://openweathermap.org/img/w/";

    const WeatherData = (lat, long) => {
        const weather = axios
            .get(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${long}&units=metric&appid=${API_KEY}`)
            .then((result) => {
                const data = result.data;
                setWeather({
                    place: data.name,
                    country: data.sys.country,
                    temperature: data.main.temp,
                    currentClimate: data.weather[0].main,
                    currentDescription: data.weather[0].description,
                    pressure: data.main.pressure,
                    humidity: data.main.humidity,
                    sea_level: data.main.sea_level,
                    grnd_level: data.main.grnd_level,
                    wind_speed: data.wind.speed,
                    weather_icon: data.weather[0].icon,
                });
                setloading(false);
            })
            .catch((err) => {
                console.clear();
            //  console.error(err);
            //  console.log(err.message)
            });
    };

    useEffect(() => {
        WeatherData(latitude, longitude);
    }, [latitude, longitude]);

    const Weather = loading ? (
        <Loader />
    ) : (
        <div className="home-container">
            <div className="main-container">
                <div className="card">
                    {weather.place && (
                        <h1>
                            City : {weather.place}, {weather.country}{" "}
                            <img src={WeatherimgUrl + weather.weather_icon + ".png"} alt="weather-icon" className="icon" />
                        </h1>
                    )}
                    {error ? <h2> Kindly allow location permission to update weather </h2> : null}
                    {weather.temperature && <h2>Temperature : {weather.temperature} ℃</h2>}
                    {weather.currentClimate && <h2>Current climate : {weather.currentClimate}</h2>}
                    {weather.currentDescription && <h2>Description : {weather.currentDescription}</h2>}
                    {weather.pressure && <h2>pressure : {weather.pressure} mbar</h2>}
                    {weather.humidity && <h2>humidity : {weather.humidity}%</h2>}
                    {weather.sea_level && <h2>Sea level : {weather.sea_level}</h2>}
                    {weather.grnd_level && <h2>Ground level : {weather.grnd_level}</h2>}
                    {weather.wind_speed && <h2>Wind speed : {weather.wind_speed} m/s</h2>}
                </div>
            </div>
        </div>
    );

    return Weather;
}

export default WeatherDataApi;
